import React from 'react';

import Meta from '../components/meta';

import { StaticImage } from "gatsby-plugin-image";

import Contents from '../components/contents';

import SubPageNav from '../components/subPageNav';

const Message = () =>{
    return(
        <>

            <Meta
                title="メッセージ"
                description="パッケージメーカーからプラットフォーマーへ。現在の仕事の魅力と苦労。システムを構築しているという感覚ではなく、しくみを構築しているという感覚で仕事をしています。しくみをお客様に提供し、 そのしくみでお客様自身が事業の障害を乗り越えていってほしい。そう考えています。ですので苦労を苦労とあまり感じません。"
            />

            <Contents>

                <div className="c-sub-header">
                    <SubPageNav static="message" />

                    <em className="c-sub-header--eng u-josef">
                    MESSAGE
                    </em>

                    <h1 className="c-sub-header--title">
                    パッケージメーカーからプラットフォーマーへ 
                    </h1>
                </div>



                <div className="p-message">

                    <div className="p-message-image">
                        <StaticImage
                            src="../images/image/p_message_image.png"
                            alt="本社"
                            placeholder="blurred"
                            quality="100"
                            className="p-message-image--image"
                        />
                    </div>

                    <article className="p-message-box-wrap">

                        <section className="p-message-box">
                            <p className="p-message-box--copy">
                            しくみをお客様に提供し、<br />
                            ともに障害を乗り越える
                            </p>
                        </section>

                        <div className="p-message-box-image">
                            <StaticImage
                                src="../images/image/p_message_cycle.png"
                                alt="ビジネスサイクル"
                                placeholder="blurred"
                                quality="100"
                                className="p-message-box-image--image"
                            />
                        </div>

                        <section className="p-message-box">
                            <p className="p-message-box--text">
                            当社は華々しい急成長のベンチャー企業ではありません。しかし、確実にお客様からの信頼を獲得し少しずつ成長してきています。 社内ミッションのひとつでもある「お客様から求め続けられる企業でありたい」「業者ではなくパートナーでありたい」をスタッフ 全員で共有し、お客様にとってなくてはならない企業のひとつになっていきたいと考えています。今後はソフトウエアだけではなく、販促や運用などに係るソリューションにも力を入れ、電子商取引のパッケージソフトメーカーから流通業及び流通分野におけるプラットフォーマーへと成長していきます。
                            </p>
                        </section>

                    </article>

                </div>

            </Contents>
        </>
    )
}

export default Message;


